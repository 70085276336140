import React from 'react';

import MainSection from './components/MainSection/MainSection';
import ArrivalSection from './components/ArrivalSection';
import SafetySection from './components/SafetySection';
import Footer from './components/Footer';
import Intercom from './components/Intercom';
import BackToTop from './components/BackToTop';

export default function App() {
  return (
    <div className="App overflow-hidden">
      <MainSection />
      <ArrivalSection />
      <SafetySection />
      <Footer />
      <Intercom />
      <BackToTop />
    </div>
  );
}
