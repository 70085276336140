import React from 'react';

import logoSquare from '../../icons/logo-square.png';
import listPhoto from '../../icons/main-list-photo.png';
import listLocation from '../../icons/main-list-location.png';
import listPay from '../../icons/main-list-pay.png';
import listPostcard from '../../icons/main-list-postcard.png';
import postcardIntro1 from '../../icons/postcard-intro-1.png';
import postcardIntro2 from '../../icons/postcard-intro-2.png';

export default function Intro() {
  return (
    <div className="pt-md-5">
      <div className="row">
        <div className="col-md-6">
          <img src={logoSquare} className="w-100" alt="logo-main" />
        </div>
        <div className="col-md-6">
          <h4>Pár nap és már a nagyi hűtőjén a fotó!</h4>
          <p
            className="pl-4"
            style={{ borderLeft: '5px solid #2ea3f2' }}
          >
            Küldj egy saját fotóból készülő képeslapot a
            nagyszülőknek, dédszülőknek! A képeslapot 48 órán belül
            elkészítjük, postára adjuk és néhány napon belül már át is
            adja a postás.
          </p>
          <div className="row my-4">
            <div className="col-2 d-flex align-items-center">
              <img
                src={listPhoto}
                className="w-100"
                alt="logo-main"
              />
            </div>
            <div className="col-10 pl-0">
              <h4 className="mb-2">Fotó</h4>
              <p className="mb-0">
                Tölts fel egy 3MB-nál nem nagyobb jpg fotót.
              </p>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-2 d-flex align-items-center">
              <img
                src={listLocation}
                className="w-100"
                alt="logo-main"
              />
            </div>
            <div className="col-10 pl-0">
              <h4 className="mb-2">Címzés</h4>
              <p className="mb-0">
                Add meg a címzettet, ahova küldöd
              </p>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-2 d-flex align-items-center">
              <img
                src={listPostcard}
                className="w-100"
                alt="logo-main"
              />
            </div>
            <div className="col-10 pl-0">
              <h4 className="mb-2">Üzenet</h4>
              <p className="mb-0">
                Írj egy rövid üzenetet, ne felejtsd el az aláírást!
              </p>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-2 d-flex align-items-center">
              <img src={listPay} className="w-100" alt="logo-main" />
            </div>
            <div className="col-10 pl-0">
              <h4 className="mb-2">Fizetés</h4>
              <p className="mb-0">
                Küldés gomb megnyomása után fizesd ki
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <a
              href="#kuldes"
              role="button"
              className="btn btn-outline-dark"
              onClick={(e) => {
                e.preventDefault();
                document.querySelector('#kuldes').scrollIntoView({
                  behavior: 'smooth',
                });
              }}
            >
              Küldök én is nagyipostát
            </a>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-6">
          <img
            src={postcardIntro1}
            className="w-100 slide-in from-left"
            alt="logo-main"
          />
        </div>
        <div className="col-md-6">
          <img
            src={postcardIntro2}
            className="w-100 slide-in from-right"
            alt="logo-main"
          />
        </div>
      </div>
    </div>
  );
}
