import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { BsShieldLockFill } from 'react-icons/bs';
import { FaCcStripe } from 'react-icons/fa';
import { RiMailCheckLine, RiMailCloseLine } from 'react-icons/ri';
import ReCAPTCHA from 'react-google-recaptcha';
import html2pdf from 'html2pdf.js';
import { Typeahead, TypeaheadMenu } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import usePostalOptions from '../../util/usePostalOptions';

export default function PostCardForm({
  postCardInformation,
  changePostCard,
  resetPostCard,
  setIsPreview,
  isPreview,
  ifPreviewed,
  setIfPreviewed,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, changeIsLoading] = useState(false);
  const [orderPlaced, changeOrderPlaced] = useState(false);
  const [orderError, changeOrderError] = useState(false);
  const [hasSubmitted, changeHasSubmitted] = useState(false);
  const [imageUploaded, changeImageUploaded] = useState(false);
  const [captchaAuthorized, changeCaptchaAuthorized] = useState(
    false,
  );
  const [
    cardElementIsComplete,
    changeCardElementIsComplete,
  ] = useState(false);

  const onFormSubmit = useCallback(
    async (event) => {
      event.persist();
      event.preventDefault();
      event.stopPropagation();
      if (!event.target) {
        return;
      }
      // 1. Check validation
      const isValid =
        event.target.checkValidity() && cardElementIsComplete;
      if (!isValid) {
        changeHasSubmitted(true);
        const firstInvalid = document.querySelector(
          '.form-control:invalid',
        );
        if (firstInvalid) {
          firstInvalid.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        } else if (!cardElementIsComplete) {
          document.querySelector('#card-element').scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
        return;
      }
      changeIsLoading(true);
      try {
        // 2. Create single-use stripe token
        const cardElement = elements.getElement(CardElement);
        const {
          error,
          token: stripeToken,
        } = await stripe.createToken(cardElement, {
          name: event.target.billingCity.value,
          address_line1: event.target.billingLine1.value,
          address_city: event.target.billingCity.value,
          address_zip: event.target.billingPostal.value,
          address_country: 'HU',
        });
        if (error) {
          throw new Error(error.message);
        }
        // 3. Create PDF
        const fileName = uuidv4();
        const blob = await html2pdf()
          .set({
            pagebreak: {
              mode: 'avoid-all',
              before: '#postCard--back',
            },
            filename: fileName,
            html2canvas: {
              useCORS: true,
              scale: 4,
              scrollX: 0,
              scrollY: 0,
            },
            jsPDF: {
              format: 'a6',
              orientation: 'l',
              unit: 'px',
            },
          })
          .from(document.getElementById('postCard'))
          .toPdf()
          .output('blob');
        // 4. Get bucketURL for pdf upload
        const {
          data: { url: bucketURL },
        } = await axios.get(
          `${process.env.REACT_APP_API_URL}/upload/${fileName}`,
        );
        // 5. Upload PDF
        const file = new File([blob], fileName, {
          type: 'application/pdf',
        });
        await axios.put(bucketURL, file, {
          headers: {
            'Content-Type': 'application/pdf',
          },
        });
        // 6. Order postcard
        await axios.post(
          `${process.env.REACT_APP_API_URL}/purchase`,
          {
            name: event.target.billingName.value,
            email: event.target.email.value,
            phone: event.target.phone.value,
            fileName,
            stripeToken,
            postCardInformation,
          },
        );
        changeOrderPlaced(true);
      } catch (error) {
        console.log(error.message);
        changeOrderError(true);
      }
      changeIsLoading(false);
      changeHasSubmitted(false);
    },
    [
      stripe,
      elements,
      changeHasSubmitted,
      changeIsLoading,
      changeOrderPlaced,
      changeOrderError,
      cardElementIsComplete,
    ],
  );

  const validationClasses = classNames(
    'needs-validation',
    hasSubmitted && 'was-validated',
  );

  const onInputChange = useCallback(
    (event) => {
      changePostCard({
        key: event.target.id,
        value: event.target.value,
      });
    },
    [changePostCard],
  );

  const changePostal = useCallback(
    (postal) => {
      changePostCard({
        key: 'postal',
        value: postal,
      });
    },
    [changePostCard],
  );

  const changeBillingPostal = useCallback(
    (postal) => {
      changePostCard({
        key: 'billingPostal',
        value: postal,
      });
    },
    [changePostCard],
  );

  const changeImage = useCallback(
    (event) => {
      const label = document.querySelector('.custom-file-label');
      label.innerHTML =
        event.target.value.replace('C:\\fakepath\\', '') ||
        'Válassz egy képet';
      if (event.target.value) {
        changeImageUploaded(true);
      } else {
        changeImageUploaded(false);
      }
      changePostCard({
        key: event.target.id,
        value: event.target?.files[0]
          ? URL.createObjectURL(event.target.files[0])
          : '',
      });
    },
    [changePostCard, changeImageUploaded],
  );

  const postalOptions = usePostalOptions({
    city: postCardInformation.city,
    line1: postCardInformation.line1,
  });
  const billingPostalOptions = usePostalOptions({
    city: postCardInformation.billingCity,
    line1: postCardInformation.billingLine1,
  });

  return (
    <>
      {(orderError || orderPlaced || isLoading) && (
        <div
          className={classNames(
            'position-fixed d-flex flex-column justify-content-center align-items-center w-100 h-100',
            { 'cursor-pointer': orderPlaced || orderError },
          )}
          style={{
            top: '0',
            left: '0',
            zIndex: '99',
            backdropFilter: 'blur(9px)',
            backgroundColor: 'rgba(255,255,255,0.7)',
          }}
          onClick={() => {
            if (orderPlaced) {
              changeOrderPlaced(false);
              document.getElementById('kuldes').reset();
              resetPostCard();
              document.querySelector('.App').scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
            if (orderError) {
              changeOrderError(false);
            }
          }}
        >
          {isLoading && (
            <div
              className="spinner-grow"
              style={{ width: '3rem', height: '3rem' }}
              role="status"
            >
              <span className="sr-only">Küldés...</span>
            </div>
          )}
          {orderPlaced && !isLoading && (
            <>
              <div
                className="row text-success"
                style={{ fontSize: '3rem' }}
              >
                <RiMailCheckLine />
              </div>
              <div
                className="row text-success mt-3 text-center"
                style={{ fontSize: '1.3rem', fontWeight: 500 }}
              >
                <div className="col px-5">
                  Rendelését fogadtuk!
                  <br />A számlát és a visszaigazoló email-t hamarosan
                  küldjük!
                </div>
              </div>
            </>
          )}
          {orderError && !isLoading && (
            <>
              <div
                className="row text-danger"
                style={{ fontSize: '3rem' }}
              >
                <RiMailCloseLine />
              </div>
              <div
                className="row text-danger mt-3 text-center"
                style={{ fontSize: '1.3rem', fontWeight: 500 }}
              >
                <div className="col px-5">Rendelés sikertelen!</div>
              </div>
            </>
          )}
        </div>
      )}
      <form
        className={validationClasses}
        noValidate
        onSubmit={onFormSubmit}
        id="kuldes"
      >
        <div className="py-4 form-group">
          <div className="row">
            <h4 className="col mb-2">Állítsd össze a képeslapod</h4>
          </div>
          <div className="row">
            <div className="col mb-2">
              Válassz egy jpg vagy png formátumú fotót, és töltsd fel.
              Ha más formátumú képed van, használd a{' '}
              <a
                href="https://cloudconvert.com/image-converter"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cloudconvert
              </a>{' '}
              ingyenes átalakítóját.{' '}
              <div className="mt-2 custom-file">
                <input
                  type="file"
                  accept=".jpg,.png"
                  className="custom-file-input"
                  id="postcardPhoto"
                  onChange={changeImage}
                  lang="hu"
                />
                <label
                  className="custom-file-label"
                  htmlFor="postcardPhoto"
                >
                  Válassz egy képet
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="message" className="font-weight-bold">
                  Üzenet
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  rows="13"
                  maxLength="300"
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name" className="font-weight-bold">
                  Címzett <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="city" className="font-weight-bold">
                  Település <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="line1" className="font-weight-bold">
                  Utca, házszám <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="line1"
                  onChange={onInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="line2" className="font-weight-bold">
                  Emelet, ajtó, egyéb
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="line2"
                  onChange={onInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="postal" className="font-weight-bold">
                  Irányítószám <span className="text-danger">*</span>
                </label>
                <Typeahead
                  id="postal"
                  inputProps={{
                    id: 'postal',
                    autoComplete: 'new-password',
                    required: true,
                    type: 'number',
                    min: 1000,
                    max: 9999,
                  }}
                  options={postalOptions}
                  placeholder="Válassz vagy adj meg egy irányítószámot"
                  onInputChange={changePostal}
                  onChange={([postal]) => changePostal(postal)}
                  renderMenu={(results, menuProps, props) => {
                    if (!results.length) {
                      return null;
                    }
                    return (
                      <TypeaheadMenu
                        {...menuProps}
                        labelKey={props.labelKey}
                        options={results}
                        text={props.text}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <h4 className="col mb-2">Megrendelés</h4>
          </div>
          <div className="row">
            <p className="col">
              A fenti képeslap elkészítését megrendelem a meghirdetett
              1000 Ft-os fogyasztói áron, amelyet a jelen űrlap
              elküldésével bankkártyával fizetek ki a Stripe.com
              védett fizetési rendszerén keresztül.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="phone" className="font-weight-bold">
                  Telefon <span className="text-danger">*</span>
                  <small className="ml-1">pl.: +36-1-501-3463</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  pattern="((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email" className="font-weight-bold">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <h5 className="col-12 mb-3">Számlázási cím</h5>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  htmlFor="billingName"
                  className="font-weight-bold"
                >
                  Megrendelő <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="billingName"
                  onChange={onInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  htmlFor="billingCity"
                  className="font-weight-bold"
                >
                  Település <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="billingCity"
                  onChange={onInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  htmlFor="billingLine1"
                  className="font-weight-bold"
                >
                  Utca, házszám <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="billingLine1"
                  onChange={onInputChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  htmlFor="billingPostal"
                  className="font-weight-bold"
                >
                  Irányítószám <span className="text-danger">*</span>
                </label>
                <Typeahead
                  id="billingPostal"
                  inputProps={{
                    id: 'billingPostal',
                    autoComplete: 'new-password',
                    required: true,
                    type: 'number',
                    min: 1000,
                    max: 9999,
                  }}
                  options={billingPostalOptions}
                  placeholder="Válassz vagy adj meg egy irányítószámot"
                  onInputChange={changeBillingPostal}
                  onChange={([postal]) => changeBillingPostal(postal)}
                  renderMenu={(results, menuProps, props) => {
                    if (!results.length) {
                      return null;
                    }
                    return (
                      <TypeaheadMenu
                        {...menuProps}
                        labelKey={props.labelKey}
                        options={results}
                        text={props.text}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="captcha" className="font-weight-bold">
                  Robotelhárítás{' '}
                  <span className="text-danger">*</span>
                </label>
                <ReCAPTCHA
                  id="captcha"
                  hl="hu"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={() => changeCaptchaAuthorized(true)}
                  onExpired={() => changeCaptchaAuthorized(false)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label
                  htmlFor="card-element"
                  className="font-weight-bold d-flex align-items-center"
                >
                  Bankkártyás fizetés{' '}
                  <span className="text-danger">*</span>
                  <FaCcStripe fontSize="1.7rem" className="ml-2" />
                </label>
                <div
                  id="card-element"
                  className={classNames(
                    'form-control d-flex align-items-center',
                    {
                      'border-danger':
                        hasSubmitted && !cardElementIsComplete,
                    },
                    {
                      'border-success':
                        hasSubmitted && cardElementIsComplete,
                    },
                  )}
                >
                  <CardElement
                    className="w-100"
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          fontSize: '1.4rem',
                          color: '#656565',
                          fontFamily: 'Montserrat',
                          letterSpacing: '0.025em',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        complete: {
                          color: '#28a745',
                          iconColor: '#28a745',
                        },
                        invalid: {
                          color: '#c23d4b',
                        },
                      },
                    }}
                    onChange={({ complete }) => {
                      if (complete !== cardElementIsComplete) {
                        changeCardElementIsComplete(complete);
                      }
                    }}
                  />
                  <BsShieldLockFill fontSize="1.7rem" />
                </div>
              </div>
            </div>
          </div>
          {!imageUploaded && (
            <>
              <div className="row">
                <h4 className="col mb-2 text-danger font-weight-bold">
                  Még nem töltöttél fel képet!
                </h4>
              </div>
              <div className="row">
                <p className="col">
                  Ha el akarod küldeni kép nélkül, azt is megteheted,
                  ekkor szép virágok lesznek a kártyán.
                </p>
              </div>
            </>
          )}
          <button
            className="btn btn-dark mr-2"
            disabled={isLoading}
            type="button"
            onClick={() => {
              setIsPreview(true);
              setIfPreviewed(true);
            }}
          >
            Előnézet
          </button>
          <button
            className="btn btn-primary"
            type="submit"
            disabled={
              !stripe ||
              !elements ||
              !captchaAuthorized ||
              isPreview ||
              !ifPreviewed
            }
          >
            Küldés
          </button>
          <p className="col ml-0 pl-0 mt-2">
            Fizetés előtt ellenőrizze a képeslap megjelenését!
          </p>
        </div>
      </form>
    </>
  );
}
