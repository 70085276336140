import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { BsArrowCounterclockwise } from 'react-icons/bs';
// import html2pdf from 'html2pdf.js';

import placeholderImage from '../../icons/postcard-placeholder.jpg';
import logoRectangle from '../../icons/logo-rectangle.png';

const postCardFront = new Image();

export default function PostCard({
  postCardInformation: {
    name,
    postcardPhoto,
    message,
    city,
    line1,
    line2,
    postal,
    billingName,
    billingCity,
    billingLine1,
    billingPostal,
  },
  isPreview,
  setIsPreview,
}) {
  // WARNING: html2canvas (dependency of html2pdf.js) has more than 500 unresolved issues, there is a TON of css properties cause it to behave unpredictable
  // Some of the bugged css props: object-fit, transfrom: translate(), overflow: hidden, background-image
  // PLEASE be VERY carefull on changing any code below, always test the preview and the pdf generation

  // html2canvas does not support object-fit, so I had to implement it myself by checking the image ratio
  const [imageSize, setImageSize] = useState('w-100');

  useEffect(() => {
    postCardFront.onload = function () {
      if (this.width / 317 > this.height / 225) {
        setImageSize('h-100');
      } else {
        setImageSize('w-100');
      }
    };
  }, [setImageSize]);

  useEffect(() => {
    if (!postcardPhoto) {
      setImageSize('w-100');
    } else {
      // This will trigger the onload above
      postCardFront.src = postcardPhoto;
    }
  }, [postcardPhoto, setImageSize]);

  const [showFrontPreview, changeShowFrontPreview] = useState(true);

  return (
    <div
      className={classNames({
        'postCard--preview position-fixed w-100 h-100 d-flex justify-content-center align-items-center flex-column': isPreview,
        'postCard--preview--back': isPreview && !showFrontPreview,
      })}
      onClick={(event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
          changeShowFrontPreview(true);
          setIsPreview(false);
        }
      }}
    >
      <div
        className={classNames('postCard', {
          'cursor-pointer': isPreview,
        })}
        onClick={() => changeShowFrontPreview(!showFrontPreview)}
      >
        <div id="postCard" className="postCard--inner w-100 h-100">
          <div
            id="postCard--front"
            className={classNames(
              'postCard--front overflow-auto d-flex align-items-center justify-content-center',
              {
                'shadow mb-4 mb-md-0': isPreview,
              },
            )}
          >
            <img
              src={postcardPhoto || placeholderImage}
              alt="postCardFront"
              className={classNames('position-relative', imageSize)}
            />
          </div>
          <div
            id="postCard--back"
            className={classNames(
              'postCard--back p-3 text-dark bg-white',
              {
                shadow: isPreview,
              },
            )}
          >
            <div className="row mb-2">
              <div className="col-7" style={{ fontSize: '6px' }}>
                {billingName}
                <br />
                {billingPostal} {billingCity} {billingLine1}
              </div>
              <div className="col-5">
                <img
                  src={logoRectangle}
                  alt="postCardLogo"
                  width="70"
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-7 font-italic"
                style={{
                  fontFamily: 'Courgette',
                  whiteSpace: 'pre-line',
                  lineHeight: '1.4',
                }}
              >
                {message}
              </div>
              <div className="col-5 mt-5 d-flex align-items-center">
                {name}
                <br />
                {line1} {line2}
                <br />
                {city}
                <br />
                {postal}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPreview && (
        <button
          type="button"
          className={classNames(
            'd-none btn text-white p-0 position-fixed border-0 rounded-circle cursor-pointer m-3 p-2',
            { 'd-md-flex': isPreview },
          )}
          style={{
            bottom: '12%',
            left: '50%',
            fontSize: '3rem',
            zIndex: 99,
            transform: 'translate(-50%) scale(1.8, 1)',
          }}
          onClick={() => changeShowFrontPreview(!showFrontPreview)}
        >
          <BsArrowCounterclockwise className="rotate-spinner" />
        </button>
      )}
    </div>
    // TEST BUTTON
    // <button
    //   onClick={() => {
    //     html2pdf()
    //       .set({
    //         pagebreak: {
    //           mode: 'avoid-all',
    //           before: '#postCard--back',
    //         },
    //         html2canvas: {
    //           useCORS: true,
    //           scale: 4,
    //           scrollX: 0,
    //           scrollY: 0,
    //         },
    //         jsPDF: {
    //           format: 'a6',
    //           orientation: 'l',
    //           unit: 'px',
    //         },
    //       })
    //       .from(document.getElementById('postCard'))
    //       .save();
    //   }}
    // >
    //   save
    // </button>
  );
}
