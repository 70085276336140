import { useState } from 'react';
import axios from 'axios';

import useDebouncedEffect from './useDebouncedEffect';

export default function usePostalOptions({ city, line1 }) {
  const [postalOptions, setPostalOptions] = useState([]);
  useDebouncedEffect(
    () => {
      const nominatimQuery = [city, line1].filter(Boolean).join(' ');
      if (nominatimQuery) {
        axios
          .get('https://nominatim.openstreetmap.org/search', {
            params: {
              q: nominatimQuery,
              country: 'hungary',
              format: 'json',
              addressdetails: 1,
            },
          })
          .then((resp) => {
            setPostalOptions(
              [
                ...new Set(
                  resp.data.map(
                    ({ address }) =>
                      address.postal_code || address.postcode,
                  ),
                ),
              ]
                .filter((postal) => postal)
                .sort(),
            );
          })
          .catch((error) => {
            console.log(error.toJSON());
          });
      }
    },
    1000,
    [city, line1],
  );
  return postalOptions;
}
