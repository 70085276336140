import React, { useCallback } from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { GoQuote } from 'react-icons/go';

import happiness from '../../icons/happiness.png';
import postcardReview1 from '../../icons/postcard-review-1.png';
import postcardReview2 from '../../icons/postcard-review-2.png';

function Review({ name, body }) {
  return (
    <div className="col-md-4 mb-5 slide-in from-top">
      <div className="bg-background p-4">
        <div
          className="position-absolute rounded-circle d-flex p-1 bg-background"
          style={{
            top: '0',
            left: '50%',
            transform: 'translate(-50%, -50%) rotate(180deg)',
            fontSize: '1.5rem',
            border: '2px solid #656565',
          }}
        >
          <GoQuote />
        </div>
        <div className="d-flex justify-content-center">
          <div
            style={{ width: '40%' }}
            className="rounded-circle overflow-hidden position-relative"
          >
            <img src={happiness} className="w-100" alt="logo-main" />
            <div
              className="shadow-inner rounded-circle position-absolute w-100 h-100"
              style={{ top: 0, left: 0 }}
            />
          </div>
        </div>
        <div className="mt-4">{body}</div>
        <div className="mt-5 font-weight-bold">{name}</div>
      </div>
    </div>
  );
}

export default function Reviews() {
  const openFacebookPopup = useCallback((e) => {
    e.preventDefault();
    window.open(
      'https://www.facebook.com/sharer.php?u=https%3A%2F%2Fnagyiposta.hu%2F',
      'popup',
      'width=560,height=760',
    );
  }, []);

  return (
    <div className="pt-5">
      <div className="d-flex flex-column align-items-center">
        <div className="row">
          <h4 className="mb-2">Jó ötlet?</h4>
        </div>
        <div className="row">
          <p className="col">
            Oszd meg mindenkivel, hátha ők is küldenének a nagyinak
            egy képeslapot!
          </p>
        </div>
      </div>
      <div className="row mb-5 pb-3">
        <div className="col-3 col-md-1">
          <div
            className="font-weight-bold"
            style={{
              color: '#cccccc',
              fontFamily: 'Helvetica Neue',
              fontSize: '43px',
              lineHeight: '0.8',
            }}
          >
            100
          </div>
          <div
            className="small pl-2 font-weight-bold"
            style={{ color: '#8A8C8E' }}
          >
            Megosztás
          </div>
        </div>
        <div className="col col-md-11">
          <a
            role="button"
            className="btn btn-primary btn-lg w-100 rounded-0 d-flex justify-content-center align-items-baseline"
            href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fnagyiposta.hu%2F"
            target="_top"
            onClick={openFacebookPopup}
          >
            <FaFacebookF />
            <span className="d-none d-md-inline-block pl-2 pt-1">
              Megosztom
            </span>
          </a>
        </div>
      </div>
      <div className="row">
        <Review
          name="L. Melinda"
          body={
            <p>
              Most hívott a nagymamám sírva, hogy megkapta és mennyire
              csodálatos meglepetés volt. Szóval köszönjük az ötletet
              és a kivitelezést!{' '}
              <img
                draggable="false"
                className="emoji"
                alt="😊"
                src="https://s.w.org/images/core/emoji/12.0.0-1/svg/1f60a.svg"
              />
              <img
                draggable="false"
                className="emoji"
                alt="❤️"
                src="https://s.w.org/images/core/emoji/12.0.0-1/svg/2764.svg"
              />
            </p>
          }
        />
        <Review
          name="Cz-K. Dóra"
          body={
            <p>
              {' '}
              A Nagymamák most hívtak elcsukló hangon, hogy
              megérkezett a posta{' '}
              <img
                draggable="false"
                className="emoji"
                alt="❤️"
                src="https://s.w.org/images/core/emoji/12.0.0-1/svg/2764.svg"
              />{' '}
              köszönöm szépen, hogy a segítségetekkel mosolyt és
              boldogságkönnyeket csaltunk arcunkra /szemükre{' '}
              <img
                draggable="false"
                className="emoji"
                alt="😍"
                src="https://s.w.org/images/core/emoji/12.0.0-1/svg/1f60d.svg"
              />
            </p>
          }
        />
        <Review
          name="K. Veronika"
          body={
            <p>
              Ma a dédink megkapta a képeslapot{' '}
              <img
                draggable="false"
                className="emoji"
                alt="😊"
                src="https://s.w.org/images/core/emoji/12.0.0-1/svg/1f60a.svg"
              />{' '}
              Nagyon boldog volt azt mondta rég őrült ennyire
              ajándéknak{' '}
              <img
                draggable="false"
                className="emoji"
                alt="😊"
                src="https://s.w.org/images/core/emoji/12.0.0-1/svg/1f60a.svg"
              />{' '}
              Köszönjük a szuper ajándék ötletet{' '}
              <img
                draggable="false"
                className="emoji"
                alt="❤️"
                src="https://s.w.org/images/core/emoji/12.0.0-1/svg/2764.svg"
              />
            </p>
          }
        />
        <Review
          name="K-H Anna"
          body={
            <p>
              Tegnap megkapták nagyszüleim (dédik) és nagyon Örültek!{' '}
              <img
                draggable="false"
                className="emoji"
                alt="😊"
                src="https://s.w.org/images/core/emoji/12.0.0-1/svg/1f60a.svg"
              />
            </p>
          }
        />
        <Review
          name="Zs-H. Kinga"
          body={
            <p>
              Nagyon köszönünk mindent! A képeslapok rendben
              megérkeztek, hatalmas örömöt okoztak a cìmzetteknek.{' '}
              <img
                draggable="false"
                className="emoji"
                alt="❤"
                src="https://s.w.org/images/core/emoji/12.0.0-1/svg/2764.svg"
              />
            </p>
          }
        />
        <Review
          name="K. Krisztina"
          body={
            <p>
              Szívből ajánlom, korrekt és nagyon jó szolgáltatás,
              olyan nagy örömöt adtak a képeslapok a nagyszülőknek.{' '}
              <img
                draggable="false"
                className="emoji"
                alt="❤"
                src="https://s.w.org/images/core/emoji/12.0.0-1/svg/2764.svg"
              />
              <br />
              Köszönjük a gyors és igényes szolgáltatást!
            </p>
          }
        />
      </div>
      <div className="d-flex justify-content-center mb-5">
        <a
          className="btn btn-outline-primary"
          role="button"
          href="https://m.me/nagyiposta"
          target="_blank"
          rel="noopener noreferrer"
        >
          Megkapta a Nagyi? Írd meg nekünk!
        </a>
      </div>
      <div className="row mt-5">
        <div className="col-md-6">
          <img
            src={postcardReview2}
            className="w-100 slide-in from-left"
            alt="logo-main"
          />
        </div>
        <div className="col-md-6">
          <img
            src={postcardReview1}
            className="w-100 slide-in from-right"
            alt="logo-main"
          />
        </div>
      </div>
    </div>
  );
}
