import { useState, useEffect } from 'react';

const getScroll = () => ({
  scrollX: (window && window.scrollX) || 0,
  scrollY: (window && window.scrollY) || 0,
});

export default function useWindowScroll(debounce) {
  const [windowScroll, setWindowScroll] = useState(getScroll());

  useEffect(() => {
    let scrollId;

    const handleScroll = () => {
      if (!debounce) {
        setWindowScroll(getScroll());
      } else {
        clearTimeout(scrollId);
        scrollId = setTimeout(
          () => setWindowScroll(getScroll()),
          debounce,
        );
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [debounce]);

  return windowScroll;
}
