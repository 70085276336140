import React from 'react';

import kerkomLogo from '../icons/kerkom-logo.png';
import logoSquare from '../icons/logo-square.png';

export default function Footer() {
  return (
    <div
      className="m-0 p-4 p-md-5"
      style={{
        backgroundColor: '#5e5e5e',
      }}
    >
      <div className="container-lg">
        <div className="row">
          <div className="col-md-6 pr-5">
            <div className="row">
              <h5 className="col mb-2 text-white">Szolgáltató</h5>
            </div>
            <div className="row">
              <p className="col-12 text-white mb-2">
                A “nagyiposta” szolgáltatást a KERKOM csoportba
                tartozó Marketainment Bt. szolgáltatja.
              </p>
              <p className="col-12 text-white">
                marketainment Bt.
                <br />
                1136 Budapest, Hegedűs Gy. u. 40.
                <br />
                +36 1 501 34 63
                <br />
                hello@marketainment.hu
                <br />
                26182434-1-41
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-4 col-md-3 d-flex justify-content-center pr-0">
                <img
                  src={logoSquare}
                  alt="logo-square"
                  style={{
                    width: '100px',
                    objectFit: 'contain',
                  }}
                />
              </div>
              <div className="col-8 col-md-9">
                <div className="row">
                  <h5 className="col mb-2 text-white">
                    Védett márkajel
                  </h5>
                </div>
                <div className="row">
                  <p className="col-12 text-white">
                    A “Nagyiposta” elnevezés védett ábrás védjegy.
                    Használata engedélyhez kötött.
                  </p>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center mt-4">
              <img
                src={kerkomLogo}
                alt="kerkom-logo"
                style={{
                  width: '220px',
                  objectFit: 'contain',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
