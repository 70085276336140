import React, { useState, useCallback, useLayoutEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Intro from './Intro';
import Reviews from './Reviews';
import FAQ from './FAQ';
import PostCard from './PostCard';
import PostCardForm from './PostcardForm';
import useWindowScroll from '../../util/useWindowScroll';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const ELEMENT_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat',
    },
  ],
};

const initialPostCard = {
  name: '',
  postcardPhoto: '',
  message: '',
  city: '',
  line1: '',
  line2: '',
  postal: '',
  billingName: '',
  billingCity: '',
  billingLine1: '',
  billingPostal: '',
};

export default function MainSection() {
  const [isPreview, setIsPreview] = useState(false);
  const [ifPreviewed, setIfPreviewed] = useState(false);
  const [postCardInformation, setPostCardInformation] = useState(
    initialPostCard,
  );

  const changePostCard = ({ key, value }) => {
    setPostCardInformation({
      ...postCardInformation,
      [key]: value,
    });
  };

  const resetPostCard = useCallback(() => {
    setPostCardInformation(initialPostCard);
    setIfPreviewed(false);
  }, [setPostCardInformation]);

  const { scrollY } = useWindowScroll(50);
  useLayoutEffect(() => {
    document
      .querySelectorAll('.slide-in:not(.slide-in--active)')
      .forEach((slider) => {
        const sliderCoords = slider.getBoundingClientRect();
        const isHalfShown =
          sliderCoords.top + sliderCoords.height / 2 <
          window.innerHeight;
        const isScrolledPast =
          0 > sliderCoords.bottom ||
          sliderCoords.top > window.innerHeight;
        if (isHalfShown && !isScrolledPast) {
          slider.classList.add('slide-in--active');
        }
      });
  }, [scrollY]);

  return (
    <div className="container-lg pt-4">
      <PostCard
        postCardInformation={postCardInformation}
        isPreview={isPreview}
        setIsPreview={setIsPreview}
      />
      <Intro />
      <Reviews />
      <FAQ />
      <Elements stripe={stripePromise} options={ELEMENT_OPTIONS}>
        <PostCardForm
          changePostCard={changePostCard}
          postCardInformation={postCardInformation}
          resetPostCard={resetPostCard}
          isPreview={isPreview}
          setIsPreview={setIsPreview}
          ifPreviewed={ifPreviewed}
          setIfPreviewed={setIfPreviewed}
        />
      </Elements>
    </div>
  );
}
