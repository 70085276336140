import React, { useMemo } from 'react';

import arrivalBackground from '../icons/arrival-background.jpg';
import arrivalMail from '../icons/arrival-mail.png';

const currentDate = new Date();

export default function ArrivalSection() {
  const dayOfWeek = useMemo(() => {
    return currentDate.toLocaleString('hu-HU', { weekday: 'long' });
  }, []);
  const postingDayText = useMemo(() => {
    if (
      (currentDate.getDay() === 1 && currentDate.getHours() < 15) ||
      (currentDate.getDay() === 3 && currentDate.getHours() < 15)
    ) {
      return 'Amit ma 15:00-ig rendelsz meg, azt HOLNAP adjuk postára, és onnantól 1-5 napon belül érkezhet meg.';
    }
    if (currentDate.getDay() === 1 || currentDate.getDay() === 2) {
      return 'Amit ma rendelsz meg, azt CSÜTÖRTÖKÖN adjuk postára, és onnantól 1-5 napon belül érkezhet meg.';
    }
    return 'Amit ma rendelsz meg, azt következő KEDDEN adjuk postára, és onnantól 1-5 napon belül érkezhet meg.';
  }, []);

  return (
    <div
      className="m-0 p-4 p-md-5"
      style={{
        backgroundBlendMode: 'soft-light',
        backgroundImage: `url(${arrivalBackground})`,
        backgroundColor: 'rgba(255,255,255,0.8)',
        backgroundPosition: '50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="container-lg">
        <div className="row d-flex justify-content-center">
          <div className="col-8 col-md-2 mb-4 mb-md-0 d-flex justify-content-center">
            <img
              src={arrivalMail}
              alt="arrival-mail"
              style={{ width: '80px', objectFit: 'contain' }}
            />
          </div>
          <div className="col-md-7 mb-5 mb-md-0 px-md-5">
            <div className="row">
              <h4 className="col mb-2 font-weight-bold text-capitalize">
                {dayOfWeek}
              </h4>
            </div>
            <div className="row">
              <p className="col font-weight-bold mb-0">
                {postingDayText}
              </p>
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-center align-items-center">
            <a
              href="https://www.posta.hu/level/level_belfoldre/elsobbsegi"
              rel="noopener noreferrer"
              target="_blank"
              role="button"
              className="btn btn-outline-dark"
            >
              Elsőbbségi levél info
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
