import React, { useState, useEffect } from 'react';
import { RiArrowUpLine } from 'react-icons/ri';
import classNames from 'classnames';

import useWindowScroll from '../util/useWindowScroll';

export default function BackToTop() {
  const [isHidden, changeIsHidden] = useState(true);
  const { scrollY } = useWindowScroll(200);
  useEffect(() => {
    if (scrollY > 600) {
      changeIsHidden(false);
    } else {
      changeIsHidden(true);
    }
  }, [scrollY]);

  return (
    <div
      className="position-fixed m-2 m-md-3 position-relative"
      style={{
        right: '0px',
        top: '0px',
        zIndex: '10',
      }}
    >
      <div
        onClick={() => {
          document.querySelector('.App').scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }}
        className={classNames(
          'back-to-top btn-primary rounded-circle p-2 cursor-pointer text-white position-absolute',
          {
            hidden: isHidden,
          },
        )}
      >
        <RiArrowUpLine fontSize="40px" />
      </div>
    </div>
  );
}
