import React from 'react';

import securityBank from '../icons/security-bank.png';
import securityGDPR from '../icons/security-gdpr.png';
import securitySSL from '../icons/security-ssl.png';

export default function SafetySection() {
  return (
    <div
      className="m-0 p-4 p-md-5"
      style={{
        backgroundColor: '#254c0d',
      }}
    >
      <div className="container-lg">
        <div className="row d-flex justify-content-center">
          <div className="col-md-4 mt-4 mt-md-0">
            <div className="d-flex justify-content-center mb-4">
              <img
                src={securitySSL}
                alt="security-ssl"
                style={{
                  width: '80px',
                  objectFit: 'contain',
                  filter: 'brightness(0) invert(1)',
                }}
              />
            </div>
            <div className="row">
              <h5 className="col mb-2 text-white">Biztonság</h5>
            </div>
            <div className="row">
              <p className="col mb-0 text-white">
                Az oldalt SSL titkosítással védjük. A böngésző
                tetején, a “nagyiposta.hu” mellett balra van egy
                lakat, erre kattintva tudod a titkosítást ellenőrizni.
              </p>
            </div>
          </div>
          <div className="col-md-4 mt-4 mt-md-0">
            <div className="d-flex justify-content-center mb-3 mt-2">
              <img
                src={securityBank}
                alt="security-back"
                style={{
                  width: '80px',
                  objectFit: 'contain',
                  filter: 'brightness(0) invert(1)',
                }}
              />
            </div>
            <div className="row">
              <h5 className="col mb-2 text-white">Fizetés</h5>
            </div>
            <div className="row">
              <p className="col mb-0 text-white">
                A <strong>stripe.com</strong> nemzetközi fizetési
                rendszert használjuk, amely az oldalon egy titkosított
                csatornán keresztül működik.
              </p>
            </div>
            <div className="row">
              <a
                href="https://stripe.com/docs/security/stripe"
                target="_blank"
                rel="noopener noreferrer"
                className="col text-white"
              >
                Info»
              </a>
            </div>
          </div>
          <div className="col-md-4 mt-4 mt-md-0">
            <div className="d-flex justify-content-center mb-4 mt-2">
              <img
                src={securityGDPR}
                alt="security-gdpr"
                style={{
                  width: '80px',
                  objectFit: 'contain',
                  filter: 'brightness(0) invert(1)',
                }}
              />
            </div>
            <div className="row">
              <h5 className="col mb-2 text-white">Adatvédelem</h5>
            </div>
            <div className="row">
              <p className="col mb-0 text-white">
                A feltöltött fotót, címet, adatokat a hatályos
                adatvédelmi rendelet alapján kezeljük. Az adatkezelési
                tájékoztatót{' '}
                <a
                  href="https://nagyiposta.hu/wp-content/uploads/2020/04/nagyiposta-adatkezelesi-tajekoztato.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white"
                >
                  itt tudod elolvasni »
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
