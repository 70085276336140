import React, { useState } from 'react';
import classNames from 'classnames';

// TODO: update react-icons when this issue is solved: https://github.com/react-icons/react-icons/issues/276
// (AI icons has invalid attributes)
import { AiOutlineComment } from 'react-icons/ai';
import {
  RiCloseLine,
  RiMessengerLine,
  RiPhoneLine,
} from 'react-icons/ri';

export default function Intercom() {
  const [isOpen, changeIsOpen] = useState(false);
  return (
    <div
      className="position-fixed m-2 m-md-3 position-relative"
      style={{
        right: '0px',
        bottom: '0px',
        zIndex: '10',
      }}
    >
      <div
        className="intercom-main btn-primary rounded-circle p-2 cursor-pointer text-white position-relative"
        onClick={() => changeIsOpen(!isOpen)}
      >
        <RiCloseLine
          fontSize="40px"
          className={classNames(
            'intercom-main-close position-absolute',
            {
              hidden: !isOpen,
            },
          )}
        />
        <AiOutlineComment
          fontSize="40px"
          className={classNames(
            'intercom-main-open position-absolute',
            {
              hidden: isOpen,
            },
          )}
        />
      </div>
      <a
        role="button"
        href="tel: +3615013463"
        rel="noopener"
        className={classNames(
          'intercom-link intercom-link-phone position-absolute rounded-circle p-2 text-white',
          {
            hidden: !isOpen,
          },
        )}
      >
        <RiPhoneLine fontSize="40px" />
      </a>
      <a
        role="button"
        href="https://m.me/nagyiposta"
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(
          'intercom-link intercom-link-messenger position-absolute rounded-circle p-2 text-white',
          {
            hidden: !isOpen,
          },
        )}
      >
        <RiMessengerLine fontSize="40px" />
      </a>
    </div>
  );
}
