import { useEffect, useRef, useCallback } from 'react';

export default function useDebouncedEffect(
  callback,
  delay,
  deps = [],
) {
  const firstUpdate = useRef(true);
  const effect = useCallback(callback, deps);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const handler = setTimeout(() => {
      effect();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, effect]);
}
