import React from 'react';
import { BsPlusCircleFill, BsDashCircleFill } from 'react-icons/bs';

function Collapse({ heading, body, headingId, collapseId }) {
  return (
    <div className="card rounded-0 mb-4">
      <div
        className="card-header bg-background border-0 p-0"
        id={headingId}
      >
        <h2 className="mb-0">
          <button
            className="btn btn-block text-left text-body font-weight-normal p-3 d-flex justify-content-between align-items-center collapse-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target={`#${collapseId}`}
            aria-expanded="false"
            aria-controls={collapseId}
          >
            {heading}
            <BsPlusCircleFill
              color="#ccc"
              className="collapse-plus"
            />
            <BsDashCircleFill
              color="#ccc"
              className="collapse-dash"
            />
          </button>
        </h2>
      </div>

      <div
        id={collapseId}
        className="collapse"
        aria-labelledby={headingId}
      >
        <div className="card-body bg-white">{body}</div>
      </div>
    </div>
  );
}

export default function FAQ() {
  return (
    <div className="pt-5">
      <div className="row">
        <h4 className="col mb-2">Mielőtt feladnád!</h4>
      </div>
      <div className="row">
        <p className="col">
          Olvasd el a gyakori kérdéseket és a válaszokat!
        </p>
      </div>
      <Collapse
        heading="Hogyan készül el a lap? Mekkora?"
        body="Az itt feltöltött fotót egy A6 méretű standard
        levelezőlapra kerül rá. A grafikus úgy méretezi a képet,
        hogy a legjobban illeszkedjen. Az üzenetet, a címzést és a
        feladó adatait a másik (címoldalra) tesszük fel. Kihagyjuk
        a helyét a bélyegnek, kinyomtatjuk és méretre vágjuk."
        headingId="hogyanKeszulHeading"
        collapseId="hogyanKeszulCollapse"
      />
      <Collapse
        heading="Biztonság, keveredés, adatvédelem"
        body={
          <div>
            <p>
              Három ponton ellenőrizzük, hogy a képek és az adatok ne
              keveredjenek össze:
              <br />
              Először amikor a grafikát készítjük.
              <br />
              Másodszor, amikor nyomtatásra a lapokat egy nagy ívre
              feltesszük.
              <br />
              Harmadszor a nyomtatás után, amikor méretre vágjuk.
            </p>
            <p>
              Ennek ellenére – emberek vagyunk, előfordulhat, hogy
              hibázunk. Ha így történik, azonnal javítjuk.
            </p>
            <p>
              A fotókat és a kész képeslap grafikákat a rendelés után
              1 hónappal fizikailag kitöröljük a rendszerünkből. Csak
              a vevőadatokat tartjuk meg, mert erre a jogszabályok
              köteleznek minket.
            </p>
          </div>
        }
        headingId="biztonsagHeading"
        collapseId="biztonsagCollapse"
      />
      <Collapse
        heading="Mikor ér oda? Mikor kerül postára?"
        body={
          <div>
            <p>
              Jelenleg képeslapot a Magyar Postával lehet küldeni,
              azaz az ő szabályaik érvényesek. A képeslapokat nem
              egyesével adjuk fel – ezesetben a szolgáltatási díj
              laponként akár 10.000 Ft is lehetne – hanem csomagban.
              Így kedden és csütörtökön visszük a postára a
              kinyomtatott lapokat.
            </p>
            <p>
              Miért nem ér oda (időben)? Nem tudjuk, és nincs eszköz
              sem rá, hogy az elsőbségi levelet kövessük. Azonban a
              postások kifaggatása után ezeket a kritikus pontokat
              tudjuk:
              <br /> 1. Nem keresi a címzett – azaz nem nézik meg
              naponta a postaládát, így nem is találnak rá a benne
              pihenő lapra.
              <br /> 2. Rossz címzés – az irányítószám a leggyakoribb
              hiba, de olykor a házszám marad el. Ilyenkor keresgélni
              kell, az pedig napokba is telhet vagy eleve rossz
              postaládába megy a lap.
              <br /> 3. Más kézbesítő kapja meg – azaz nem a megfelelő
              körzetbe indul el a lap. Ebben az esetben a kézbesítő
              visszaviszi a forduló végén, és másnap elindul a lap a
              jó irányba.
              <br /> 4. Fekete lyukba kerül – a képeslapok a mosógépbe
              tett félpár zoknik sorsára jutnak, és egy végtelen
              tömegű fekete lyuk szippantja be őket a logisztikai
              rendszerben, ahonnan vagy soha nem kerülnek elő többé
              vagy egy random időpontban felbukkannak a címzettnél.
            </p>
            <p>
              A Magyar Posta jelenleg 3-5 napot vállal kézbesítésre
              normál levél esetén, elsőbbségi levélnél az alábbit
              vállalja:
              <br />{' '}
              <em>
                “az első kézbesítési kísérletig, a postai kezelés
                minden szakaszában kiemelten kezeljük, így több mint
                90%-os arányban, a feladást követő munkanapon már
                célba is érnek.”
              </em>{' '}
              Azaz, ha mi kedden adjuk fel, akkor elvileg már szerdán
              ott kellene lennie, ha csütörtökön, akkor pénteken. A
              gyakorlat azonban azt mutatja a vevőink visszajelzéséből
              és a saját tesztküldéseinkből, hogy mindenhogyan 1-5 nap
              között ér oda a lap. És időnként előfordul, hogy soha
              nem ér oda vagy csak nagyon sokára. Ennek okát nem
              tudjuk. Azonban ha egy lap megszökik, akkor azt mi ahogy
              jelzi a feladó, hogy nem érkezett meg, újranyomjuk, és
              ismét elindítjuk.{' '}
              <strong>
                Így igyekszünk helyt állni a kézbesítés
                esetlegességéért is.
              </strong>
            </p>
          </div>
        }
        headingId="erkezesHeading"
        collapseId="erkezesCollapse"
      />
    </div>
  );
}
